import React from 'react';
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'

const About = () => {

  return (
    <section className='section' id='about'>
      <div className="container mx-auto">
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          {/* ABOUT */}
          <motion.div
            variants={fadeIn('right', 0.5)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'>
            <h2 className='h2 text-accent'>About me & Skills</h2>
            <p className='mb-6 lg:text-[16px] text-[14px]'>I am a skilled front-end developer with a specialization in JavaScript. Passionate about creating dynamic and interactive web experiences, I combine my technical expertise with a creative approach to craft seamless user interfaces and engaging user experiences.</p>
          </motion.div>
          {/* SKILLS */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'>
            <h5 className='font-bold'>Front-end</h5>
            <p className='mb-6 lg:text-[16px] text-[14px]'>
              JavaScript, React, Next, Redux/Context/Zustand, React-Query, HTML, CSS, SASS, Styled Components, Tailwind, Material UI</p>
            <h5 className='font-bold'>Back-end</h5>
            <p className='lg:text-[16px] text-[14px]'>Node.js, Express, NestJS, FeathersJS, PostgreSQL, MongoDB, Firestore, Docker, AWS, Netlify, Firebase</p>
            <h5 className='font-bold mt-6'>Tools</h5>
            <p className='lg:text-[16px] text-[14px]'>Visual Studio Code, Git, Github, Fork, Figma, Canva</p>
          </motion.div>
        </div>
      </div>
    </section>

  )
};

export default About;
