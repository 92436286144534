import React, { useRef } from 'react';
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import emailjs from '@emailjs/browser';

const Contact = () => {

  // Email JS
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        'service_nhsdfek',
        'template_mbsn6c8',
        form.current,
        'xgSl7VHnan-R_F5N1'
      )
      .then(
        (result) => {
          console.log(result.text)
          toast.success(
            'Thank you for your message; it has been duly noted. I will get back to you promptly.',
            {
              autoClose: 4000,
            }
          )
          setTimeout(() => {
            document.location.href = '/'
          }, 10000)
        },
        (error) => {
          console.log(error.text)
        }
      )
  }




  return (
    <section className='py-16 lg:section' id='contact'>
      <div className="container mx-auto">
        <div className='flex flex-col lg:flew-row xl:flex-row'>
          {/* text */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden" whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex justify-start items-start'>
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide'>Get in touch</h4>
              <h2 className='text-[45px] lg:text-[70px] leading-none mb-12'>Let's work <br /> together</h2>
            </div>
          </motion.div>
          {/* form */}
          <motion.form
            ref={form} onSubmit={sendEmail}
            variants={fadeIn('left', 0.3)}
            initial="hidden" whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='lg:text-[16px] text-[14px] flex-1 flex flex-col gap-y-6 pb-24 p-6 items-start'>
            <input type="name"
              name="user_name" className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all' placeholder='Your name' />
            <input type="email"
              name="user_email"
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all' placeholder='Your email' required />
            <textarea name="message"
              className='bg-transparent border-b py-12 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12' placeholder='Your message' required minLength={10}
            ></textarea>
            <button type='submit'
              value='Send' className='btn btn-lg'>SEND MESSAGE</button>
          </motion.form>
        </div>
      </div>
      <ToastContainer />
    </section>
  )
};

export default Contact;
