import React from 'react';
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'
import ImgWork1 from '../assets/work/VFPIMAGE.png'
import ImgWork2 from '../assets/work/LAMOURBRUTIMAGE.png'
import ImgWork3 from '../assets/work/VFIMAGE.png'

const Work = () => {
  return (
    <div className='section' id='work'>
      <div className="container mx-auto">
        <div className='flex flex-col lg:flex-row gap-x-10'>
          <div className=''>
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden" whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0'>
            {/* text */}
            <div>
              <h2 className='h2 leading-tight text-accent'>My Lastest <br /> Work</h2>
              <p className='max-w-sm mb-14 lg:text-[16px] text-[14px]'>As a developer and designer, I had the opportunity to work on some exciting projects. I enjoyed the challenge of crafting user-friendly interfaces and ensuring seamless development. It's always a pleasure to bring ideas to life.</p>
              <button onClick={() => window.open('https://github.com/TomChaseParis', '_blank')}
                type="button" className='btn btn-sm'>VIEW ALL PROJECTS</button>
            </div>
            {/* image 3 */}
            <div className='group relative overflow-hidden'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              {/* img */}
              <img className='group-hover:scale-125 transition-all duration-500 max-w-[600px] max-sm:max-w-[350px]' src={ImgWork3} alt="" />
              {/* pretitle */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-accent'>Front Developement / UI/UX Design</span></div>
              {/* title */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'><a href="https://vincent-fernandel.com/" target="_blank" rel="noopener noreferrer">Vincent Fernandel</a></span>
              </div>
            </div>
          </motion.div>
            
          </div>
          
          <motion.div
            variants={fadeIn('left', 0.2)}
            initial="hidden" whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex flex-col'>
            {/* image 1 */}
            <div className='group relative overflow-hidden'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              {/* img */}
              <img className='group-hover:scale-125 transition-all duration-500 max-w-[600px] max-sm:max-w-[350px]' src={ImgWork1} alt="" />
              {/* pretitle */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-accent'>Front Developement / UI/UX Design</span></div>
              {/* title */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'><a href="https://vincentfernandelproduction.com/" target="_blank" rel="noopener noreferrer">VFP Production</a></span>
              </div>

            </div>
            {/* image 2 */}
            <div className='group relative overflow-hidden'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              {/* img */}
              <img className='group-hover:scale-125 transition-all duration-500 max-w-[600px] max-sm:max-w-[350px]' src={ImgWork2} alt="" />
              {/* pretitle */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-accent'>Front Developement / UI/UX Design</span></div>
              {/* title */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'><a href="https://lamour-brut.com/" target="_blank" rel="noopener noreferrer">L'Amour Brut</a></span>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  )
};

export default Work;