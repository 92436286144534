import React from 'react';

const Header = () => {

  return (
    <header className='py-8'>
      <div className="container mx-auto">
        <div className='flex justify-between items-center'>
          {/* logo */}
          <a className='font-primary' href="/">TC</a>
          <button
            className='btn btn-sm'
            onClick={() => {
              const contactSection = document.getElementById('contact');
              if (contactSection) {
                contactSection.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }
            }}
          >
            CONTACT ME
          </button>
        </div>
      </div>
    </header>
  )

};

export default Header;
