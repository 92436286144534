import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
// eslint-disable-next-line
import { TypeAnimation } from 'react-type-animation'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'

const Banner = () => {

  return (
    <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
      <div className="container mx-auto">
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          {/* text */}
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1
              variants={fadeIn('up', 0.3)}
              initial="hidden" whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='text-[45px] font-primary font-bold leading-[1.5] lg:text-[80px]'>Thomas <span>Chazal</span></motion.h1>
            <motion.div
              variants={fadeIn('up', 0.4)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-6 text-[20px] lg:text-[30px] font-secondary font-semibold  leading-[1]'>
              <span className='text-white mr-2'>I am a</span>
              <TypeAnimation sequence={[
                'Front-end Developer',
                2000,
                'JavaScript Developer',
                2000,
                'React Developer',
                2000,
              ]}
                speed={5}
                className='text-accent'
                wrapper='span'
                repeat={Infinity} />
            </motion.div>

            <motion.p
              variants={fadeIn('up', 0.5)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-8 max-w-lg mx-auto lg:mx-0 lg:text-[16px] text-[14px]'>I'm a software engineer specializing in front-building (and occasionally designing) exceptional digital experiences.</motion.p>


            {/* socials */}
            <motion.div
              variants={fadeIn('up', 0.7)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'
            >
              <button onClick={() => window.open('https://github.com/TomChaseParis', '_blank')}
                type="button"
              >
                <FaGithub />
              </button>
              <button onClick={() => window.open('https://www.linkedin.com/in/thomas-chazal-8b89b36a/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=fr', '_blank')}
                type="button">
                <FaLinkedin />
              </button>
            </motion.div>

          </div>
          {/* image */}
          <div>

          </div>

        </div>
      </div>
    </section>
  )
};

export default Banner;
